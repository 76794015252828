.titleInput .MuiInput-input {
	text-align: center;
}

.MuiAccordionSummary-button {
	padding-inline-end: calc(var(--ListItem-paddingRight) - 8px) !important;
}

.tab {
	text-overflow: ellipsis;
	flex-grow: 1;
	display: flex;
	justify-content: center !important;
	height: 3em;
	width: 3em;

	@media (max-width: 768px) {
		height: 50px;
		width: 3em;
	}

	svg {
		scale: 1.2;
	}

	span {
		font-size: 32px;
	}
}

.tabPanel {
	max-height: 100%;
	width: 80%;

	@media (max-width: 768px) {
		overflow-y: auto;
		box-shadow: 0px -3px 5px #3333;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
		overflow-x: hidden;
		background-color: white;
		max-height: 40svh;
		width: 100vw;
		position: absolute;
		bottom: 8svh;
		> button {
			position: fixed;
			right: 10px;
			z-index: 10;
			border-radius: 50px;
			background-color: var(--outline);
		}
	}
}

.fullscreen {
	display: flex;
	justify-content: center;

	color: var(--primary);
	width: 100%;

	cursor: pointer;

	&:hover {
		scale: 1.09;
	}
}
