.infoWrapper {
	display: flex;
	flex-direction: column;
	gap: 1em;

	.infoBox {
		display: flex;
		flex-direction: row;
		align-items: center;

		border: 1px solid var(--primary);
		border-radius: 0.5rem;

		padding: 1em;

		.info {
			display: flex;
			flex-direction: column;

			margin-right: 1em;
		}
	}
}
