.emojis {
	width: 100%;

	em-emoji-picker {
		width: 100%;
		height: 70vh;
		@media (max-width: 768px) {
			height: 35vh;
		}
	}
}
