.mugImage {
	width: 100%;
	border-radius: 0.25rem;
}

// .company {
// 	&.active {
// 		border: solid 1px var(--secondary);
// 		border-radius: 0.5rem;
// 	}
// }

.qtdInput .MuiInput-input {
	text-align: center;
}
