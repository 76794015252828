.cropper-container,
.cropper-crop,
.cropper-wrap-box {
	border-radius: 0.5rem;

	.cropper-point {
		height: 12px !important;
		width: 12px !important;
		z-index: 10 !important;
		opacity: 1 !important;
		background-color: #5b7cf2;
	}

	.line-e,
	.line-w {
		width: 3px !important;
	}

	.line-n,
	.line-s {
		height: 3px !important;
	}
}
