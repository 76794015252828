.creator {
	height: 100dvh;
	overflow: hidden;

	.topMenu {
		width: 100%;
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: var(--primary);

		.mugName {
			color: white;
		}

		.profileMenu {
			background-color: transparent;
			color: white;

			&:hover {
				opacity: 0.9;
			}

			.profileWrapper {
				display: flex;
				align-items: center;

				cursor: pointer;

				.text {
					margin-left: 5px;
				}
			}
		}
	}

	.motor {
		height: calc(100dvh - 50px);
		margin-top: 50px;
		@media (max-width: 768px) {
			width: 100%;
		}
	}

	.contextMenu {
		display: flex;
		height: 90px;
		width: 100%;
	}
}
.MuiSnackbar-root {
	min-width: 0 !important;
}
