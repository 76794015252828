.gridBox {
	background-color: var(--joy-palette-neutral-50);
	// background-color: var(--background);
	border-radius: 0.5rem;
	padding: 1em;
	display: flex;
	align-items: center;
	flex-direction: column;
	border: solid 1px var(--outline);
	// box-shadow: var(--joy-shadow-sm);

	width: 100%;
	height: 100%;
}
