.imageCardCrop {
	border: solid 2px var(--outline);
	border-radius: 0.5rem;
	width: 100%;
	aspect-ratio: 1;
	background-repeat: no-repeat !important;
	background-position: center center !important;
	background-size: 100% !important;
	transition: 0.2s;
	&:hover {
		scale: 1.05;
		box-shadow: var(--shadow);
	}
}
