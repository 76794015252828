.body {
	display: flex;
	flex-direction: row;
	gap: 1em;

	.buttonWrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 1em;

		.addressInfo {
			display: flex;
			gap: 0.5em;
		}
	}
}
