#collectionBoard {
	position: relative;
	margin: calc(var(--Grid-rowSpacing) / 2) calc(var(--Grid-columnSpacing) / 2);
	margin-bottom: 1em;
	box-shadow: var(--shadow);

	overflow: hidden;

	border-radius: 0.5rem;

	background-color: black;

	.blobBox {
		position: absolute;
		height: 100%;
		width: 100%;
		overflow: hidden;
		z-index: 0;

		.blob {
			position: absolute;
			height: 300px;
			width: 300px;
			filter: blur(40px);
			scale: 3;

			@media (max-width: 768px) {
				height: 150px;
				width: 150px;
			}
		}

		:nth-child(1) {
			bottom: -25%;
			left: 50%;
			opacity: 0;

			@media (max-width: 768px) {
				bottom: -20%;
				left: -15%;
			}
		}

		:nth-child(2) {
			bottom: -55%;
			right: -10%;

			@media (max-width: 768px) {
				top: -15%;
				right: -5%;
				bottom: auto;
			}
		}

		:nth-child(3) {
			top: -40%;
			left: 55%;

			@media (max-width: 768px) {
				bottom: -5%;
				right: -5%;
				top: auto;
				left: auto;
			}
		}

		:nth-child(4) {
			top: -30%;
			right: 10%;
			opacity: 0;

			@media (max-width: 768px) {
				top: 0%;
				left: -15%;
				right: auto;
			}
		}
	}

	.title {
		position: relative;
		font-size: 30pt;
		padding: 0.5rem;
		padding-left: 1rem;
		color: white;
		font-size: 2em;
		z-index: 1;
	}
}
