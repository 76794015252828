.cardPreview {
	transition: 0.2s;
	cursor: pointer;

	border-radius: 0.5rem !important;
	box-shadow: 0 0 0 #fff;

	scroll-snap-align: center;
	scroll-snap-stop: always;

	img {
		scale: 1.01;
	}

	.MuiCardCover-root {
		border-radius: 0.35rem;
	}

	&:hover {
		// box-shadow: var(--shadow);
		// scale: 1.02;
		box-shadow:
			-10px 1px 20px rgba(114, 41, 116, 0.6),
			10px 1px 20px rgba(248, 111, 3, 0.6);
	}

	.cardSubMenuIcon,
	.cardFavorite {
		&:hover {
			background-color: transparent;
			scale: 1.2;
		}
	}

	.cardFavorite {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		color: var(--primary);
	}
	.cardFavorite2 {
		position: absolute;
		top: 3rem;
		right: 0.5rem;
		color: var(--primary);
	}
	.cardFavorite3 {
		position: absolute;
		top: 6rem;
		right: 0.5rem;
		color: var(--primary);
	}
}
