@property --angle {
	syntax: "<angle>";
	initial-value: 0deg;
	inherits: false;
}
.cards {
	position: relative;
	border: none !important;
	// background-color: var(--outline) !important;
	cursor: pointer;
	box-shadow: var(--shadow);
	border-radius: 0.4rem !important;

	&.active::after,
	&.active::before {
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		background-image: conic-gradient(from var(--angle), transparent 15%, var(--primary));
		top: 50%;
		left: 50%;
		translate: -50% -50%;
		z-index: -1;
		padding: 3px;
		border-radius: 0.5rem;
		animation: 1s spin forwards;
		box-sizing: unset;
	}

	&.active::before {
		// filter: blur(0.2rem);
		opacity: 0.5;
	}

	.cardTitle {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.cardBody {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		:nth-child(2) {
			flex-grow: 1;
		}
	}
}

@keyframes spin {
	from {
		--angle: 0deg;
	}
	to {
		--angle: 360deg;
	}
}
